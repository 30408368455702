<template>
    <tr>
        <td v-for="(i, index) in columns" :key="i">
            <span
                class="h-4 inline-block text-transparent linear-background"
                :class="{ 'w__50': index === 0, 'w-3/4': index }"
            >
                Loading
            </span>
        </td>
    </tr>
</template>
<script>
export default {
    props: {
        columns: Array,
    },
}
</script>